import React from 'react'

const NotFound = () => {
  return (
    <main>
        <p>404: Page not found</p>
    </main>
  )
}

export default NotFound