export class LunaJoyFoundationService {

    static FOUNDATION_URL = process.env.REACT_APP_FOUNDATION_LINK

    static checkWhetherPatientExist = async (clinic, firstName, lastName, dob) => {
        const currentDate = new Date()
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        // Combine the components into the desired format
        const formattedDate = `${year}-${month}-${day}`;
        try {
            const isPatientExistResponse = await fetch(`${LunaJoyFoundationService.FOUNDATION_URL}/isPatientExist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    dob: dob,
                    appointmentDate: formattedDate,
                    clinic: clinic,
                }),
            });

            if (isPatientExistResponse.ok) {
                return await isPatientExistResponse.json();
            } else {
                console.error('Failed to fetch data:', isPatientExistResponse.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    static getClinicDetails = async () => {
        try {
            const response = await fetch(`${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners`);

            if (response.status === 200) {
                const resData = await response.json();
                console.log(resData);
                return resData;
            } else {
                throw {
                    message: 'Could not fetch events.',
                    status: 500
                };
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static getClinicBySlug = async (slug) => {
        try {
            const response = await fetch(`${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners/slug/${slug}`);

            if (response.status === 200) {
                const resData = await response.json();
                return resData;
            } else {
                throw {
                    message: 'Could not fetch events.',
                    status: 500
                };
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static getClinicByCode = async (code) => {
        try {
            const response = await fetch(`${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners/code/${code}`);

            if (response.status === 200) {
                const resData = await response.json();
                return resData;
            } else {
                throw {
                    message: 'Could not fetch events.',
                    status: 500
                };
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static getPatientWithContactId = async (contactId) => {
        try {
            const response = await fetch(`${LunaJoyFoundationService.FOUNDATION_URL}/api/universe/ghl-contact/${contactId}`);

            if (response.status === 200) {
                const resData = await response.json();
                return resData;
            } else {
                throw {
                    message: 'Could not fetch events.',
                    status: 500
                };
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static getInsuranceList = async () => {
        try {
            const response = await fetch(`${LunaJoyFoundationService.FOUNDATION_URL}/api/insurances`);

            if (response.status === 200) {
                const insurances = await response.json();
                const insuranceList = insurances.map((insurance) => insurance.title);
                return insuranceList;
            } else {
                throw {
                    message: 'Could not fetch events.',
                    status: 500
                };
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
}