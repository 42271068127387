import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import FieldError from '../fieldError/FieldError.js'
import validateField from '../validation.js'
import styles from './Radio.module.scss'
import RadioOption from './RadioOption.js'
import { useEffect, useState } from 'react'

const subscription = {
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true
}
const validateFields = []

// eslint-disable-next-line react/prop-types
const Radio = ({ defaultValue, label, name, options, schema, onChange }) => {
  const [optionsList, setOptionsList] = useState([])
  const { input, meta } = useField(name, {
    subscription,
    type: 'radio',
    validate: schema && validateField(schema),
    validateFields,
    defaultValue
  })

  useEffect(() => {
    let i = 0
    setOptionsList(options.map(option => {
        i = i + 1
        return {id: i, title: option, value: option}
    }))
  }, [options])

  const handleOptionChange = (event) => {
    input.onChange(event); 
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={styles.radio}>
      <label className={styles.label} style={{ color: 'black' }}>{label}<span style={{ color: 'red', marginBottom: '-5rem' }}>*</span></label>
      <div className={styles.options}>
        {optionsList?.map((option) => (
          <RadioOption
            key={`${option.title}_${option.id}`}
            name={name}
            option={option}
            onChange={handleOptionChange}
          />
        ))}
      </div>
      <FieldError {...meta} />
    </div>
  )
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    parse: PropTypes.func.isRequired
  }),
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func 
}

Radio.defaultProps = {
  options: undefined,
  schema: undefined,
  onChange: undefined
}

export default Radio
