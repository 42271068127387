const generateHeadText = (clinic) => {
    switch (clinic.matchedScreening.type) {
        case 'obgyn':
            return {
                title: `${clinic.name} Ob-Gyn screening`,
                description: `LunaJoy ${clinic.name} Ob-Gyn screening form`
            }
        case 'phq9-gad7':
            return {
                title: `${clinic.name} Depression and Anxiety screening`,
                description: `LunaJoy ${clinic.name} Depression and Anxiety screening form`
            }
        case 'gds':
            return {
                title: `${clinic.name} Geriatric screening`,
                description: `LunaJoy ${clinic.name} Geriatric screening form`
            }
        default:
            return {
                title: `${clinic.name}'s Form`,
                description: `LunaJoy ${clinic.name}'s screening form`
            }
    }  
}

export default generateHeadText