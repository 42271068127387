const clinics = {
  'associates-of-marietta': {
    clinicians: [
      'Dr. Chappell',
      'Dr. Huffman',
      'Dr. Kelley',
      'Dr. Killian',
      'Dr. Needham',
      'Dr. Tackitt II',
      'Dr. Windom',
      'Beth Kirkley',
      'Christie Tucker',
      'Christine Jennings',
      'Jasmine Powell',
      'Leah Peacock',
      'Monica Clark',
      'Richelle Zischke'
    ],
    formOptions: {
      anxietyQuestions: true,
      pregnancyStatusQuestion: true
    },
    startPageUrl: 'https://hellolunajoy.com/associates-of-marietta',
    title: 'Associates of Marietta',
    foundationCode: 'associates-of-marietta'
  },
  arbor: {
    clinicians: [
      'Dr. Alvarez',
      'Dr. Beatty',
      'Dr. Gray',
      'Dr. Breazeale',
      'Dr. Deibel',
      'Dr. DePasquale',
      'Ashley Schaublin',
      'Elizabeth McGrath',
      'Meghan Presnell',
      'Rachel Runkle'
    ],
    formOptions: {
      anxietyQuestions: false,
      pregnancyStatusQuestion: false
    },
    startPageUrl: 'https://hellolunajoy.com/arbor',
    title: 'Arbor',
    foundationCode: 'arbor'
  },
  'clifton-obgyn': {
    clinicians: [
      'Dr. Anish Shah',
      'Dr. Ashley Papapetrou',
      'Dr. Charles Haddad',
      'Dr. Nyota Peace',
      'Dina Abdelslame'
    ],
    formOptions: {
      anxietyQuestions: true,
      pregnancyStatusQuestion: true
    },
    startPageUrl: 'https://hellolunajoy.com/clifton-obgyn',
    title: 'Clifton OB/GYN',
    foundationCode: 'clifton-ob-gyn'
  },
  dekalb: {
    clinicians: [
      'Dr. Bowen',
      'Dr. Brown',
      'Dr. Jaffree',
      'Dr. Pham',
      'Dr. Rodatus'
    ],
    formOptions: {
      anxietyQuestions: true,
      pregnancyStatusQuestion: true
    },
    startPageUrl: 'https://hellolunajoy.com/obgyn',
    title: 'DeKalb',
    foundationCode: 'dekalb'
  },
  'gentle-hands': {
    clinicians: [
      'Dr. Ashby',
      'Dr. King',
      'Dr. Witkowski',
      'Molly Barnes'
    ],
    formOptions: {
      anxietyQuestions: true,
      pregnancyStatusQuestion: true
    },
    startPageUrl: 'https://hellolunajoy.com/gentle-hands',
    title: 'Gentle Hands',
    foundationCode: 'gentle-hands'
  },
  'magnolia-birth-house': {
    clinicians: [
      'Dawna Insua',
      'Dellareece Bastian',
      'Jamarah Amani',
      'Jessica Willison',
      'Kimberley Homer',
      'Brenda Francis',
      'Margo Dandreano',
      'Tamara Taitt'
    ],
    formOptions: {
      anxietyQuestions: true,
      pregnancyStatusQuestion: true
    },
    startPageUrl: 'https://hellolunajoy.com/magnolia-birth-house',
    title: 'Magnolia Birth House',
    foundationCode: 'magnolia-birth-house'
  },
  northpoint: {
    clinicians: [
      'Dr. Anissa Durairaj',
      'Dr. Bret C. Lewis',
      'Dr. E.J. Aspuru',
      'Dr. Jill T. Cierny',
      'Dr. Lori Marshall',
      'Dr. Robbie Conatser',
      'Ashleigh M. Clark',
      'Garland S. Andres'
    ],
    formOptions: {
      anxietyQuestions: true,
      pregnancyStatusQuestion: true
    },
    startPageUrl: 'https://hellolunajoy.com/northpoint',
    title: 'Northpoint',
    foundationCode: 'northpoint'
  }
}

export default clinics