import { useRecoilState } from 'recoil'
import { clinicState } from '../../globalState'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Background from '../../components/Background/Background'
import styles from './NonClinic.module.scss'
import Loader from '../../components/Loader/Loader'
import WelcomePage from '../../components/FormCompoent/WelcomePage/WelcomePage'
import JotForm from '../../components/FormCompoent/JotForm/JotForm'
import PersonalInfo from '../../components/FormCompoent/PersonalInfo/PersonalInfo'
import PatientService from '../../services/PatientService'
import JotFormService from '../../services/JotFormService'
import generate from '../../utilities/generatePatientMatchingErrorModalContent'
import jotFormIds from '../../data/jot-form/form-ids-mapping'
import { LunaJoyFoundationService } from '../../services/LunajoyFoundationService'
import { formatDate } from '../../utilities/dateFormat'
import { Modal } from 'antd'

const NonClinic = ({ slug }) => {
  const { path } = useParams()
  const [screening, setScreening] = useState(null)
  const [{ clinic, error }, setClinic] = useRecoilState(clinicState)
  const [loading, setLoading] = useState(false)
  const [formId, setFormId] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [jotFormLink, setJotFormLink] = useState(null)
  const [personalDetails, setPersonalDetails] = useState(null)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const contact_id = queryParams.get('contactId')
  const email = queryParams.get('email')

  useEffect(() => {
    setClinic({})
    console.log(slug)
    if (slug === "phq9-gad7") {
      setScreening("PHQ9-GAD7")
      setFormId(jotFormIds['phq9-gad7'])
    } else if (slug === 'obgyn' || path === "obgyn") {
      setScreening("EPDS")
      setFormId(jotFormIds['epds-anxiety'])
    } else {
      setScreening(null)
    }

  }, [])

  useEffect(() => {
    if (contact_id) {
      setCurrentPage('personal-information')
      //   loadPersonalInfo();

    }

    if (email) {
      setCurrentPage('personal-information')
      setPersonalDetails({ email: email })
    }
  }, [contact_id, email])

  const loadPersonalInfo = async () => {
    try {
      setLoading(true);

      const ghlContact = await LunaJoyFoundationService.getPatientWithContactId(contact_id)
      const phone = ghlContact.phone_no
      ghlContact['phone_no'] = phone.slice(2);
      setPersonalDetails(ghlContact)
      console.log(ghlContact)

      setLoading(false);
    } catch (error) {
      console.error("Not found patient in ghl")
    }
  }

  const handlePersonalInfoSubmit = async (values) => {
    window.rudderanalytics.page();
    try {
      setLoading(true)

      const result = await PatientService.findOrCreatePatient({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email || email,
        phone: values.phone,
        dob: formatDate(values.dob)
      })
      console.log(result)
      if (result?.findOrCreatePatient?.mismatch) {
        const errorReason = generate(result?.findOrCreatePatient?.mismatch)

        let data = {}
        let formIdTemp
        if (screening === "EPDS") {
          data = {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOf: formatDate(values.dob),
            phone: values.phone,
            email: values.email || email,
            screening: 'EPDS',
            tags: 'obgyn',
            isExisting: !!contact_id,
            contactId: result?.findOrCreatePatient?.contactId,
            pregnancyStatus: values.pregnancyStatus,
            isTemporary: "true",
            mismatchReason: errorReason
          }
          formIdTemp = jotFormIds['epds-anxiety-temp']
        } else {
          data = {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOf: formatDate(values.dob),
            phone: values.phone,
            email: values.email || email,
            screening: 'PHQ9-GAD7',
            tags: 'phq9-gad7',
            isExisting: !!contact_id,
            contactId: result?.findOrCreatePatient?.contactId,
            isTemporary: "true",
            mismatchReason: errorReason
          }
          formIdTemp = jotFormIds['phq9-gad7-temp']
        }

        const formLink = JotFormService.generateJotFormLink(formIdTemp, data)
        setJotFormLink(formLink)
        setCurrentPage('jot-form')

        // const { title, content, helpMessage } = generate(result?.findOrCreatePatient?.mismatch)
        // Modal.error({
        //   title,
        //   content: <>{content}<br /><p>{helpMessage}</p></>,
        //   okText: 'OK',
        //   centered: true,
        //   okButtonProps: { style: { width: 60 } }
        // })
      } else if (result?.findOrCreatePatient?.contactId) {
        let data = {}
        if (screening === "EPDS") {
          data = {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOf: formatDate(values.dob),
            phone: values.phone,
            email: values.email || email,
            screening: 'EPDS',
            tags: 'obgyn',
            isExisting: !!contact_id,
            contactId: result?.findOrCreatePatient?.contactId,
            pregnancyStatus: values.pregnancyStatus,
            isTemporary: "false",
            mismatchReason: "Not Applicable"
          }
        } else {
          data = {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOf: formatDate(values.dob),
            phone: values.phone,
            email: values.email || email,
            screening: 'PHQ9-GAD7',
            tags: 'phq9-gad7',
            isExisting: !!contact_id,
            contactId: result?.findOrCreatePatient?.contactId,
            isTemporary: "false",
            mismatchReason: "Not Applicable"
          }
        }

        const formLink = JotFormService.generateJotFormLink(formId, data)
        setJotFormLink(formLink)
        setCurrentPage('jot-form')
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  };

  if (!screening) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}>{'Sorry, this screening is not available'}</h1>
        </div>
      </Background>
    </main>
  )


  return (
    <main>
      {!currentPage
        ? <WelcomePage setCurrentPage={setCurrentPage} />
        : currentPage === 'personal-information'
          ? <PersonalInfo handleSubmit={handlePersonalInfoSubmit} types={screening} personalDetails={personalDetails} loading={loading} setCurrentPage={setCurrentPage} />
          : <JotForm JotFormLink={jotFormLink} formId={formId} clinicDetails={clinic} />}
    </main>
  )
}

export default NonClinic;