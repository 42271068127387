import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { clinicState } from '../../../globalState'
import Loader from '../../Loader/Loader'
import JotForm from '../../FormCompoent/JotForm/JotForm'
import { LunaJoyFoundationService } from '../../../services/LunajoyFoundationService'
import { formatDateFromSql } from '../../../utilities/dateFormat'
import jotFormIds from '../../../data/jot-form/form-ids-mapping'
import JotFormService from '../../../services/JotFormService'

const EMRDirectScreening = ({ screeningType }) => {
  const { clinic } = useRecoilValue(clinicState)
  const [loading, setLoading] = useState(false)
  const [jotFormLink, setJotFormLink] = useState(null);
  const location = useLocation();
  const formId = jotFormIds[screeningType]
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const getJotFormLink = useCallback(
    async () => {
    window.rudderanalytics.page();
      try {
        setLoading(true)

        const ghlContact = await LunaJoyFoundationService.getPatientWithContactId(id)

        const formLink = JotFormService.generateJotFormLink(formId, {
          clinicName: clinic.name,
          firstName: ghlContact.firstname,
          lastName: ghlContact.lastname,
          dateOf: formatDateFromSql(ghlContact.dob),
          phone: ghlContact.phone_no.slice(2),
          email: ghlContact.email,
          tags: clinic.tags,
          clinicCode: clinic.clinicCode,
          screening: screeningType === 'phq9-gad7' ? 'PHQ9-GAD7' : 'EPDS',
          isExisting: !!id,
          contactId: id,
        })
        setJotFormLink(formLink)
        setLoading(false);
      } catch (error) {
        console.error(error)
      }
    },
    [clinic, formId, id, screeningType],
  )

  useEffect(() => {
    getJotFormLink()
  }, [getJotFormLink])

  return <>
    {loading ? <Loader /> : <JotForm JotFormLink={jotFormLink} formId={formId} clinicDetails={clinic} />}
  </>
};

export default EMRDirectScreening;