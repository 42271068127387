import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import Layout from './Layout';
import Screening from './pages/screening/Screening';
import ObgynPage from './pages/obgyn/ObgynPage';
import PromoPage from './pages/promo/PromoPage';
import './index.scss';
import NotFound from './pages/404/404';
import NonClinic from './pages/non-clinic/NonClinic';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { RudderStackWriteKey, RUDDERSTACK_DATAPLANE_URL } from './utilities/urls';
import { generateId } from './utilities/generateId';
import './i18n'

const rudderAnalytics = new RudderAnalytics();

const rudderStackEventSourceKey = RudderStackWriteKey();
const rudderStackEventUrl = RUDDERSTACK_DATAPLANE_URL;

function initRudderStack() {
  const uniqueId = generateId();
  rudderAnalytics.load(rudderStackEventSourceKey, rudderStackEventUrl, {
    integrations: {
      All: true
    },
    logLevel: 'DEBUG',
  })
  rudderAnalytics.identify(uniqueId);
}

// Effect for initializing RudderStack 
function InitRudderStackEffect() {
  useEffect(() => {
    initRudderStack();

    if (typeof window !== 'undefined') {
      window.rudderanalytics = rudderAnalytics
    }
  }, []);
  return null;
}


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route path='' element={<NonClinic slug={'phq9-gad7'} />} />
      <Route path='screening/'>
        <Route path='phq9-gad7' element={<NonClinic slug={'phq9-gad7'} />} />
        <Route path='obgyn' element={<NonClinic slug={'obgyn'} />} />
        <Route path=':slug' element={<Screening />} />
        <Route path=':slug/:direct' element={<Screening />} />
      </Route>
      <Route path='ob-gyn/'  >
        <Route path=':slug' element={<ObgynPage />} />
      </Route>
      <Route path='ob-gyn/promo/' element={<PromoPage />} >
        <Route path=':slug' element={<PromoPage />} />
      </Route>
      <Route path='404/' element={<NotFound />} />
      <Route path='*'>
        <Route path=':path' element={<NonClinic />} />
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <InitRudderStackEffect />
    <RouterProvider router={router} />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// added comment
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
