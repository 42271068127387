import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import FieldError from '../fieldError/FieldError';
import validateField from '../validation';
import styles from './Select.module.scss';

const subscription = {
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true,
  value: true
};
const validateFields = [];

const Select = ({ defaultValue, name, label, schema, options, optionLabel, isRequired, onChange, ...rest }) => {
  const { input, meta } = useField(name, {
    subscription,
    type: 'select',
    validate: schema && validateField(schema),
    validateFields,
    defaultValue: defaultValue || options[0]?.title,
    ...rest
  });

  useEffect(() => {
    if (defaultValue === '' || defaultValue) {
      input.onChange(defaultValue);
    }
  }, [input, options, defaultValue]);

  const hasError = Boolean(
    meta.touched && (Array.isArray(meta.error) ? meta.error.length > 0 : meta.error)
  );

  const handleSelectChange = (event) => {
    input.onChange(event); 
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={styles.select}>
      <label htmlFor={input.name}>
        {label}
        {isRequired && <span style={{ color: 'red', marginBottom: '-5rem' }}>*</span>}
      </label>
      <select
        className={hasError ? styles.inputFieldError : ''}
        id={input.name}
        {...input}
        onChange={handleSelectChange} 
      >
        <option value="" className={styles.selectOption} disabled hidden>
          {optionLabel ? optionLabel : 'Please select an option'}
        </option>
        {options.map((title) => (
          <option key={title} className={styles.selectOption} value={title}>
            {title}
          </option>
        ))}
      </select>
      <FieldError {...meta} />
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    parse: PropTypes.func.isRequired
  }),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  schema: undefined,
  optionLabel: 'Please select an option',
  isRequired: false,
  onChange: undefined,
};

export default Select;
