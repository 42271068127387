import JotformEmbed from 'react-jotform-embed';
import TopLogo from '../../TopLogo/TopLogo';
import { Image } from 'antd';
import styles from './JotForm.module.scss'
import HipaaImage from '../../../assets/images/Hipaa.svg'
import LunajoyLogoWhite from '../../../assets/images/LunajoyLogoWhite.svg'

const JotForm = ({ JotFormLink, formId,  clinicDetails }) => {

    return (<div className={styles.gridContainer}>
        <div className={styles.topLogoContainer}>
            <div className={styles.bottomLeftImage}>
          <Image src={LunajoyLogoWhite} alt="info image" className={styles.logoImage} preview={false} />
        </div>
        </div>
      
        <JotformEmbed src={JotFormLink} scrolling='yes' className={styles.jotForm} />
        
        <div className={styles.bottomRightImage}>
            <Image src={HipaaImage} alt="info image" className={styles.hipaaImage} />
        </div>
    </div>)
}

export default JotForm;