/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-len */
import { useEffect } from 'react'
import { Image, ConfigProvider } from 'antd'
import PropTypes from 'prop-types'
import HipaaImage from '../../assets/images/Hipaa.svg'
import LunajoyLogoWhite from '../../assets/images/LunajoyLogoWhite.svg'
import styles from './Background.module.scss'
import LanguageSwitcher from '../Languages/LanguageSwitcher'

const Background = ({ children, clinicDetails }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          // Cusstomize antd theme component wise
          Steps: {
            colorPrimary: '#ffb21c'
          }
        }
      }}
    >
      <div className={styles.gridContainer}>
        <div className={styles.topContainer}>
          <LanguageSwitcher />
        </div>
        <div className={styles.childrenContainer}>
          {children}
        </div>
        <div className={styles.bottomLeftImage}>
          <Image src={LunajoyLogoWhite} alt="info image" className={styles.logoImage} preview={false} />
        </div>
        <div className={styles.bottomRightImage}>
          <Image src={HipaaImage} alt="info image" className={styles.hipaaImage} preview={false} />
        </div>
      </div>
    </ConfigProvider>
  )
}

Background.propTypes = {
  children: PropTypes.node.isRequired
}

export default Background
