export const DEV_BACKEND_URL = "https://api.forms-dev.hellolunajoy.com/graphql"
export const LOCAL_BACKEND_URL = "http://localhost:5000/graphql"
export const PROD_BACKEND_URL = "https://api.forms.hellolunajoy.com/graphql"

export const BACKEND_URL = window.location.hostname === 'forms.hellolunajoy.com' ? PROD_BACKEND_URL : window.location.hostname === 'forms-dev.hellolunajoy.com' ? DEV_BACKEND_URL : LOCAL_BACKEND_URL

//booking url for insurance
export const DEV_BOOKING_URL = 'https://booking-dev.hellolunajoy.com'
export const LOCAL_BOOKING_URL = 'http://localhost:3001'
export const PROD_BOOKING_URL = 'https://booking.hellolunajoy.com'

export const BOOKING_APP_URL = window.location.hostname === 'forms.hellolunajoy.com' ? PROD_BOOKING_URL : window.location.hostname === 'forms-dev.hellolunajoy.com' ? DEV_BOOKING_URL : LOCAL_BOOKING_URL

export const DEV_FOUNDATION_URL = "https://api.portal-dev.hellolunajoy.com"
export const LOCAL_FOUNDATION_URL = "http://localhost:6001"
export const PROD_FOUNDATION_URL = "https://api.portal.hellolunajoy.com"

export const FOUNDATION_URL = window.location.hostname === "forms.hellolunajoy.com" ? PROD_FOUNDATION_URL : window.location.hostname === "forms-dev.hellolunajoy.com" ? DEV_FOUNDATION_URL : LOCAL_FOUNDATION_URL

export const DEV_PARTNER_LANDING_URL = "https://partner-dev.hellolunajoy.com"
export const LOCAL_PARTNER_LANDING_URL = "http://localhost:3002"
export const PROD_PARTNER_LANDING_URL = "https://partner.hellolunajoy.com"

export const PARTNER_LANDING_URL = window.location.hostname === "forms.hellolunajoy.com" ? PROD_PARTNER_LANDING_URL : window.location.hostname === "forms-dev.hellolunajoy.com" ? DEV_PARTNER_LANDING_URL : LOCAL_PARTNER_LANDING_URL

export const DEV_FORM_URL = "forms-dev.hellolunajoy.com";
export const LOCAL_FORM_URL = "http://localhost:3001";
export const PROD_FORM_URL = "forms.hellolunajoy.com";
export const RUDDERSTACK_DATAPLANE_URL = 'https://hellolunajdotr.dataplane.rudderstack.com';

export const RudderStackWriteKey = () => {
    if (window.location.hostname === PROD_FORM_URL) {
        return "2mtCuCTjAZcDkC4gAUfmITSWGrc";
    } else if (window.location.hostname === DEV_FORM_URL || window.location.origin === LOCAL_FORM_URL) {
        return "2mtCwNgj67iNRnhNCUaKnED1GIi";
    }
};