import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import FieldError from '../fieldError/FieldError'
import validateField from '../validation'
import styles from './Input.module.scss';

const subscription = {
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true,
  value: true
}

const Input = ({ autofocus, classname, defaultValue, disabled, label, max, name, placeholder, schema, type, onChange, value, ...rest }) => {
  const { input, meta } = useField(name, {
    subscription,
    type,
    validate: schema && validateField(schema),
    defaultValue,
    ...(type === 'number' && {
      parse: (value) => (value ? Number(value) : undefined)
    }),
    ...rest
  })

  const [localError, setLocalError] = useState(null);
  const ref = useRef(null)

  useEffect(() => {
    if (defaultValue === '' || defaultValue) {
      input.onChange(defaultValue)
    }
  }, [input, defaultValue])

  useEffect(() => {
    if (autofocus) {
      ref.current.focus()
    }
  }, [autofocus])

  const inputValue = value !== undefined ? value : input.value;

  const handleChange = (e) => {
    const currentValue = e.target.value;
    input.onChange(e) 

    if (schema) {
      try {
        schema.parse(currentValue);
        setLocalError(null); 
      } catch (error) {
        setLocalError(error?.errors?.[0]?.message || 'Invalid input'); 
      }
    }

    if (onChange) {
      onChange(e)
    }
  }

  const hasError = Boolean(
    meta.touched && (Array.isArray(meta.error) ? meta.error.length > 0 : meta.error)
  );

  return (
    <div className={`${styles.input} ${styles[name] ? styles[name] : ''}`}>
      {label && <label htmlFor={input.name} style={{ color: 'black' }}>{label} <span style={{ color: 'red', marginBottom: '-5rem' }}>*</span></label>}
      <input
        id={input.name}
        ref={ref}
        className={hasError || localError ? styles.inputFieldError : ''}
        disabled={disabled}
        max={max}
        placeholder={placeholder}
        {...input}
        value={inputValue}
        onChange={handleChange}
        onKeyUp={handleChange} 
      />
      <div className={styles.errorContainer}>
        <FieldError
          dirtySinceLastSubmit={meta.dirtySinceLastSubmit}
          error={localError || meta.error}
          submitError={meta.submitError}
          touched={meta.touched || !!localError} 
        />
      </div>
    </div>
  )
}

Input.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  max: PropTypes.string,
  schema: PropTypes.shape({
    parse: PropTypes.func.isRequired
  }),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

Input.defaultProps = {
  disabled: false,
  max: undefined,
  schema: undefined,
  type: 'text',
  onChange: undefined,
  value: undefined,
}

export default Input
