import { ZodError, ZodIssueCode, setErrorMap } from 'zod'

function processValue(value) {
  if (typeof value === 'string') {
    return value.trim()
  }
  return value
}

const pluralize = (count, noun) => (
  `${count} ${noun}${count !== 1 ? 's' : ''}`
)

const customErrorMap = (error, context) => {
    if (error.code === ZodIssueCode.invalid_type) {
        if (error.received === 'undefined') {
        return { message: 'This field is required' }
        }
    }

    if (error.code === ZodIssueCode.invalid_string) {
        if (error.validation === 'email') {
            return { message: 'Must be a valid email' }
        }
    }

    if (error.code === ZodIssueCode.invalid_literal) {
        if (error.expected === true) {
            return { message: 'Required' }
        }
    }

    if (error.code === ZodIssueCode.invalid_enum_value) {
        return { message: `Must be one of valid values: ${error.options.join(', ')}` }
    }

    if (error.code === ZodIssueCode.too_small) {
        return { message: `Must contain at least ${pluralize(error.minimum, 'character')}` }
    }

    if (error.code === ZodIssueCode.custom) {
        return { message: error.message }
    }

    return { message: context.defaultError }
}

setErrorMap(customErrorMap)

export default function validateField(schema) {
    return (value) => {
        try {
            schema.parse(processValue(value))
            return null
        } catch (error) {
            if (error instanceof ZodError) {
                // eslint-disable-next-line no-underscore-dangle
                return error.format()._errors[0]
            }
            return 'Validation error'
        }
    }
}
