import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

const generate = (errorReason) => {
  console.log({ mismatch: errorReason })
  let title

  const conditions = {
    firstName: true,
    lastName: true,
    phone: true,
    dob: true,
    email: true
  };

  switch (errorReason) {
    case 'email':
      title = "We Couldn't Match All Your Info, Let's Double-Check"
      // email doesn't match with contact in GHL with provided firstName, lastName, dob & phone
      conditions.email = false
      break
    case 'phone':
      title = "We Couldn't Match All Your Info, Let's Double-Check"
      // phone number doesn't match with contact in GHL with provided firstName, lastName, dob & email
      conditions.phone = false
      break
    case 'email_phone':
      title = "We Couldn't Match All Your Info, Let's Double-Check"
      // email & phone number doesn't match with contact in GHL with provided firstName, lastName & dob
      conditions.email = false
      conditions.phone = false
      break
    case 'invalid_email':
      title = 'The Email Already Exists'
      // Email exist in GHL with different firstName, lastName, dob & phone
      conditions.firstName = false
      conditions.lastName = false
      conditions.dob = false
      conditions.phone = false
      break
    case 'invalid_phone':
      title = 'The Phone Number Already Exists'
      // Phone number exist in GHL with different firstName, lastName, dob & email
      conditions.firstName = false
      conditions.lastName = false
      conditions.dob = false
      conditions.email = false
      break
    case 'invalid_email_phone':
      title = 'The Email And Phone Number Already Exists'
      // Phone number & email exist in GHL with different firstName, lastName & dob
      conditions.firstName = false
      conditions.lastName = false
      conditions.dob = false
      break
    default:
      break
  }

  function replaceMatchedStatus(inputString, conditions) {
    // Split the input string into an array for easier processing
    let resultString = inputString;
    let fields = ['First Name', 'Last Name', 'Phone', 'DOB', 'Email'];

    // Replace placeholders based on conditions
    fields.forEach(field => {
      let status = "Matched"; // default status
      if (field === 'First Name' && !conditions.firstName) status = "Not Matched";
      if (field === 'Last Name' && !conditions.lastName) status = "Not Matched";
      if (field === 'Phone' && !conditions.phone) status = "Not Matched";
      if (field === 'DOB' && !conditions.dob) status = "Not Matched";
      if (field === 'Email' && !conditions.email) status = "Not Matched";

      resultString = resultString.replace('<Matched_Status>', status);
    });

    return resultString;
  }

  const inputString = "First Name: <Matched_Status>, Last Name: <Matched_Status>, Phone: <Matched_Status>, DOB: <Matched_Status>, Email: <Matched_Status>";

  const outputString = replaceMatchedStatus(inputString, conditions);

  // const helpMessage = 'If you are still having trouble, please call or text (727)-291-9538.'

  // const renderErrorContent = () => {
  //   if (errorReason === 'phone') {
  //     return `First Name: Matched , Last Name: Matched, Date of Birth: Matched, Phone: Doesn't Matched, Email: Matched`

  //     // return (
  //     //   <>
  //     //     <p>Welcome back! For your security, we noticed that some details do not match our records. Please check again and resubmit.</p>
  //     //     <br />
  //     //     <p>First Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //     //     <p>Last Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //     //     <p>Date of Birth: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //     //     <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //     //     <p>Email: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //     //   </>
  //     // )
  //   }
  //   if (errorReason === 'email') {
  //     return (
  //       <>
  //         <p>Welcome back! For your security, we noticed that some details do not match our records. Please check again and resubmit.</p>
  //         <br />
  //         <p>First Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Last Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Date of Birth: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Phone: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //       </>
  //     )
  //   }
  //   if (errorReason === 'email_phone') {
  //     return (
  //       <>
  //         <p>Welcome back! For your security, we noticed that some details do not match our records. Please check again and resubmit.</p>
  //         <br />
  //         <p>First Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Last Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Date of Birth: <CheckCircleFilled style={{ color: 'green' }} /></p>
  //         <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //         <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //       </>
  //     )
  //   }
  //   if (errorReason === 'invalid_email_phone') {
  //     return (
  //       <>
  //         <p>Your email and phone number are already linked to a patient&apos;s account. Please try a different email and phone number to continue.</p>
  //         <br />
  //         <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //         <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //       </>
  //     )
  //   }
  //   if (errorReason === 'invalid_phone') {
  //     return (
  //       <>
  //         <p>Your phone number is already linked to a patient&apos;s account. Please try a different phone number to continue.</p>
  //         <br />
  //         <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //       </>
  //     )
  //   }
  //   if (errorReason === 'invalid_email') {
  //     return (
  //       <>
  //         <p>Your email is already linked to a patient&apos;s account. Please try a different email to continue.</p>
  //         <br />
  //         <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
  //       </>
  //     )
  //   }
  //   return null
  // }

  // return { title, content: renderErrorContent(), helpMessage }

  return outputString
}

export default generate