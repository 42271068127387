import i18n from 'i18next'
import LanguageDetecter from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './lang/en/translation.json'
import es from './lang/es/translation.json'

i18n
    .use(LanguageDetecter)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        resources: {
            en,
            es
        }
    })