import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { clinicState } from '../../../globalState';
import Button from '../../forms/Button/Button';
import styles from './WelcomePage.module.scss'
import Background from '../../Background/Background';
import { useLocation, useParams } from 'react-router-dom';
import { LunaJoyFoundationService } from '../../../services/LunajoyFoundationService';
import Loader from '../../Loader/Loader';

const WelcomePage = ({ setCurrentPage }) => {
    const location = useLocation();
    const { slug } = useParams()
    const [loading, setLoading] = useState(false)
    const { clinic } = useRecoilValue(clinicState);
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contactId');
    const email = queryParams.get('email')
    const id = queryParams.get('id')

    const handleClick = () => {
        setCurrentPage('personal-information')
    }

    const oldLandingPageClinics = [
        "arbor",
        "associates-of-marietta",
        "dekalb",
        "gentle-hands",
        "orlando-health",
        "clifton-ob-gyn",
        "university-park",
        "magnolia-birth-house",
        "northpoint",
        "lunajoy",
        "reproductive-endocrinology-and-infertility-group",
        // "children-s-medical-group",
        "cmg",
        "magic-city",
        "suncoast-womens-care",
        "unified-premier-womens-care",
        // "women-s-specialists-of-northside-gwinnett",
        "womens-care-specialists-of-northside-gwinnett",
        "just-for-you-womens-healthcare",
        "cartersville-obgyn",
        "south-tampa-gynecology",
        "marinhealth",
        "cone-health-femina",
        "heart-2-heart",
        "dfw-endocrinology",
        "womens-care",
        "mental-health-screening-assessment",
        "e-lovu-texas-clinic",
        // "cone-health-center-for-womens-healthcare-at-kernersville",
        "cone-health-kernersville",
        // "cone-health-center-for-womens-healthcare-at-drawbridge-parkway",
        "cone-health-drawbridge-parkway",
        "miami-femwell-health",
        "wellstar-gme",
        "hca-florida-mercy-hospital",
        "mother-goose-clinic"
    ]

    const redirectToPartnerLandingPage = useCallback(async () => {
        try {
            if (oldLandingPageClinics.includes(slug) || oldLandingPageClinics.includes(slug?.replace("-obgyn", "")) || oldLandingPageClinics.includes(slug?.replace("-phq9-gad7", ""))) {
                return
            } else if (window.location.pathname.includes(`screening/${slug}`) && !contact_id && !email && !id) {
                setLoading(true)
                const clinicInfo = await LunaJoyFoundationService.getClinicBySlug(slug)

                window.open(clinicInfo?.landingPageUrl, "_self", "noopener,noreferrer")
            }
        } catch (error) {
            setLoading(false)
            console.error("Error in redirectToPartnerLandingPage - ", error)
        }
    }, [slug])

    useEffect(() => {
        redirectToPartnerLandingPage()
    }, [redirectToPartnerLandingPage])


    return <>
        {loading ? <Loader /> : <Background clinicDetails={clinic} >
            <div className={styles.container}>
                <h1 className={styles.heading}>Welcome to the Mental Well-being Assessment!</h1>
                <div className={styles.contents}>
                    <div className={styles.forms}>
                        <p className={styles.para}>This confidential and interactive tool is crafted to evaluate your emotional health</p>
                        <div className={styles.buttonContainer}>
                            <Button type="button" onClick={handleClick}>Take Assessment</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Background>}
    </>

};

export default WelcomePage
