export default class PatientService {

    static GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_LINK

    static findOrCreatePatient = async (data) => {
        const query = `mutation findOrCreatePatient($input: FindOrCreatePatientInput!) { findOrCreatePatient(input: $input) { contactId mismatch } }`
        const operationName = 'findOrCreatePatient'
        const variables = {
            input: data
        }

        try {
            const response = await fetch(PatientService.GRAPHQL_URL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({query, operationName, variables}), 
            });

            if (response?.status === 200) {
                const res = await response.json()
                return res.data
            } else {
                throw {
                    message: 'Could not fetch events.',
                    status: 500
                }
            }
        } catch (error) {
            console.error('Error:', error)
            throw error
        }
    }
}