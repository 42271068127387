import { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './FieldError.module.scss';

const FieldError = ({ dirtySinceLastSubmit, error, submitError, touched }) => (
  ((touched && error) || (!dirtySinceLastSubmit && submitError))
    ? <p className={styles.fieldError}>{error || submitError}</p>
    : null
)

FieldError.propTypes = {
  dirtySinceLastSubmit: PropTypes.bool.isRequired,
  error: PropTypes.string,
  submitError: PropTypes.string,
  touched: PropTypes.bool.isRequired
}

FieldError.defaultProps = {
  error: undefined,
  submitError: undefined
}

export default memo(FieldError)
