
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

// Updated button element to handle the style for back buttons
// eslint-disable-next-line react/prop-types
const Button = ({ center, children, classname, onClick, type, ...rest }) => {


  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} onClick={onClick} className={styles.button} {...rest}>{children}</button>
  )
}

Button.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit'])
}

Button.defaultProps = {
  center: false,
  type: 'button'
}

export default Button
