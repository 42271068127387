import React from "react"
import styles from './Loader.module.scss'
import { ConfigProvider, Spin } from 'antd';


const Loader = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.div}>
                <ConfigProvider
                    theme={{
                        components: {
                            Spin: {
                                colorPrimary: 'var(--color-emerald-forest)'
                            }
                        }
                    }}
                >
                    <Spin size="large" />
                </ConfigProvider>
            </div>
        </div>
    );
}

export default Loader