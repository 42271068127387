
export class JotFormService {
    static GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_LINK

    static FormDynamicUpdate = async(formId, fieldId, options) => {
        const query = `mutation updateJotFormFieldOptions($input: UpdateJotFormFieldOptionsInput!) {
            updateJotFormFieldOptions(input: $input)
          }`
        const operationName = 'updateJotFormFieldOptions'
        const variables = {
            input: {
                formId,
                fieldId,
                options
            }
        }

        try {
            const response = await fetch(JotFormService.GRAPHQL_URL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({query, operationName, variables}), 
            });
            return 
            
        } catch (error) {
            console.error('Error:', error)
            throw error
        }
    }

    static generateJotFormLink = (formId, data) => {
        let query = ''
        for (const [key, value] of Object.entries(data)) {
            if(value) {
                if(query != '') {
                    query += '&'
                }
                query += `${key}=${encodeURIComponent(value)}`
            }
        }
        return `https://form.jotform.com/${formId}?${query}`
    }
}

export default JotFormService;