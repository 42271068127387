import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useParams, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import Background from '../../components/Background/Background';
import { clinicState } from '../../globalState';
import styles from './ObgynPage.module.scss';
import Loader from '../../components/Loader/Loader';
import clinics from '../../data/clinics';
import { LunaJoyFoundationService } from '../../services/LunajoyFoundationService';
import JotForm from '../../components/FormCompoent/JotForm/JotForm';
import PersonalInfo from '../../components/FormCompoent/PersonalInfo/PersonalInfo';
import WelcomePage from '../../components/FormCompoent/WelcomePage/WelcomePage';
import PatientService from '../../services/PatientService';
import generate from '../../utilities/generatePatientMatchingErrorModalContent';
import { formatDate } from '../../utilities/dateFormat';
import jotFormIds from '../../data/jot-form/form-ids-mapping';
import JotFormService from '../../services/JotFormService';

const ObgynPage = () => {
  const { slug } = useParams()
  const [{ clinic, error }, setClinic] = useRecoilState(clinicState)
  const [loading, setLoading] = useState(false)
  const [formId, setFormId] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [jotFormLink, setJotFormLink] = useState(null);
  const [personalDetails, setPersonalDetails] = useState(null)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contact_id = queryParams.get('contactId');
  const email = queryParams.get('email')

  const loadClinic = useCallback(async () => {
    try {
      const { foundationCode } = clinics[slug];
      const clinic = await LunaJoyFoundationService.getClinicByCode(foundationCode)
      setClinic({ clinic: { ...clinic, formOptions: clinics[slug].formOptions }, error: null })
      document.title = `${clinic?.name} Ob-Gyn screening`
    } catch (error) {
      console.log(error)
      setClinic({ clinic: null, error: 'Not Found' })
    }
  }, [slug])


  useEffect(() => {
    if (slug) {
      loadClinic()
    }
  }, [slug])

  useEffect(() => {
    if (contact_id) {
      setCurrentPage('personal-information')
      // loadPersonalInfo();

    }
    if (email) {
      setCurrentPage('personal-information')
      setPersonalDetails({ email: email })
    }
  }, [contact_id, email])

  const loadPersonalInfo = async () => {
    try {
      setLoading(true);

      const ghlContact = await LunaJoyFoundationService.getPatientWithContactId(contact_id)
      const phone = ghlContact.phone_no
      ghlContact['phone_no'] = phone.slice(2);
      setPersonalDetails(ghlContact)
      console.log(ghlContact)

      setLoading(false);
    } catch (error) {
      console.error("Not found patient in ghl")
    }
  }

  const handlePersonalInfoSubmit = async (values) => {
    window.rudderanalytics.page();
    try {
      setLoading(true)

      const result = await PatientService.findOrCreatePatient({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email || email,
        phone: values.phone,
        dob: formatDate(values.dob)
      })
      console.log(result)
      if (result?.findOrCreatePatient?.mismatch) {
        const errorReason = generate(result?.findOrCreatePatient?.mismatch)

        const screeningType = clinic.formOptions.anxietyQuestions ? 'epds-anxiety-temp' : 'epds-anxiety';
        const jotFormId = jotFormIds[screeningType]
        setFormId(jotFormId)

        const formLink = JotFormService.generateJotFormLink(jotFormId, {
          clinicName: clinic.name,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOf: formatDate(values.dob),
          phone: values.phone,
          email: values.email || email,
          tags: clinic.tags,
          clinicCode: clinic.clinicCode,
          screening: 'EPDS',
          isExisting: !!contact_id,
          contactId: result?.findOrCreatePatient?.contactId,
          pregnancyStatus: values.pregnancyStatus,
          isTemporary: "true",
          mismatchReason: errorReason
        })
        setJotFormLink(formLink)
        setCurrentPage('jot-form')

        // const { title, content } = generate(result?.findOrCreatePatient?.mismatch)
        // Modal.error({
        //   title,
        //   content: <p>{content}</p>,
        //   okText: 'OK',
        //   centered: true,
        //   okButtonProps: { style: { width: 60 } }
        // })
      } else if (result?.findOrCreatePatient?.contactId) {
        const screeningType = clinic.formOptions.anxietyQuestions ? 'epds-anxiety' : 'epds-anxiety-temp';
        const jotFormId = jotFormIds[screeningType]
        setFormId(jotFormId)

        const formLink = JotFormService.generateJotFormLink(jotFormId, {
          clinicName: clinic.name,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOf: formatDate(values.dob),
          phone: values.phone,
          email: values.email || email,
          tags: clinic.tags,
          clinicCode: clinic.clinicCode,
          screening: 'EPDS',
          isExisting: !!contact_id,
          contactId: result?.findOrCreatePatient?.contactId,
          pregnancyStatus: values.pregnancyStatus,
          isTemporary: "false",
          mismatchReason: "Not Applicable"
        })
        setJotFormLink(formLink)
        setCurrentPage('jot-form')
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  };



  if (error || !slug) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}>{'Sorry, this screening is not available'}</h1>
        </div></Background>
    </main>
  )

  if (!clinic) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}><Loader /></h1>
        </div>
      </Background>
    </main>
  )

  return (
    <main>
      {!currentPage
        ? <WelcomePage setCurrentPage={setCurrentPage} />
        : currentPage === 'personal-information'
          ? <PersonalInfo handleSubmit={handlePersonalInfoSubmit} types={"EPDS"} personalDetails={personalDetails} loading={loading} setCurrentPage={setCurrentPage} />
          : <JotForm JotFormLink={jotFormLink} formId={formId} clinicDetails={clinic} />}
    </main>
  )
}

export default ObgynPage