import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import styles from './RadioOption.module.scss'

const subscription = {
  value: true
}

const RadioOption = ({ name, option, onChange }) => {
  const { input } = useField(name, {
    subscription,
    type: 'radio',
    value: option.value 
  })
  const element = `${input.name}-${option.id}`

  return (
    <div className={styles.radioOption}>
      <input
        id={element}
        className={styles.radioOption}
        {...input}
        onChange={(e) => {
          input.onChange(e) 
          onChange(e) 
        }}
        value={option.value}
      />
      <label htmlFor={element}>{option.title}</label>
    </div>
  )
}

RadioOption.propTypes = {
  name: PropTypes.string.isRequired,
  option: PropTypes.exact({
    id: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func 
}

export default RadioOption
