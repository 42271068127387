const pregnancyStatuses = (t) => {
  const PersonalInformation_notPregnant = t('PersonalInformation_notPregnant')
  const PersonalInformation_pregnant = t('PersonalInformation_pregnant')
  const PersonalInformation_postpartum = t('PersonalInformation_postpartum')

  return ([
    PersonalInformation_notPregnant,
    PersonalInformation_pregnant,
    PersonalInformation_postpartum
  ])
}

export default pregnancyStatuses